<template lang="pug">
  v-popover(
    :open="value"
    :popoverInnerClass="popoverClass"
    :disabled="disabled"
    :placement="placement"
    :boundariesElement="boundariesElement"
    @update:open="updatePopupOpenStatus"
  )
    slot
    template(v-slot:popover)
      slot(name="popup")
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    value: Boolean,
    placement: {
      type: String,
      default: 'bottom-start',
      validator: function (val) {
        return ['bottom', 'top-start', 'left' ,'top', 'top-end', 'bottom-start', 'bottom-center', 'bottom-end'].includes(val)
      }
    },
    popoverClass: [Array, String],
    boundariesElement: [String, Element],
    disabled: Boolean
  },

  methods: {
    onOutsideClick() {
      this.$emit('click:outside')
    },

    updatePopupOpenStatus(e) {
      if (this.disabled) return
      this.$emit('input', e)
    }
  }
}
</script>

<style lang="scss">
.popover {
  z-index: 9999;
  overflow-y: scroll;
  max-width: 200px;
  max-height: 300px;
  width: auto;
  border-radius: 8px;
  background-color: $body-bg;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.18);
}

.v-popover .trigger {
  width: 100%;
}

</style>
