<template lang="pug">
  .app-select.app-input(:class="[classList, classObject]")
    base-label(:required="requiredField" :label="label")
    app-popup(
      v-model="showing"
      :placement="placement"
      :disabled="disabled"
      :boundariesElement="boundariesElement"
      :popoverClass="['app-select_open', popoverClasses]"
    )
      slot(name="field" :toggle="toggleSelect" :updateShowing="updateShowing")
        .app-input__wrapper(@click="toggleSelect" :class="{'app-input__wrapper_focus': showing, 'app-input__wrapper_transparent': transparent}")
          .app-input__before(:class="{'app-input__before_highlight': selectedValue}")
            slot(name="before" :selectedOption="selectedOption")
          slot(name="input")
            input(readonly :value="selectedValue" :placeholder="placeholder").app-input__input
          .app-input__after
            img(
              v-show="!hideArrow"
              src="@/assets/img/ui/arrow-down.svg"
              :class="{'app-select__arrow_open': showing}"
            ).app-select__arrow
          img(src="@/assets/img/ui/close.svg" v-if="showCloseBtn" @click.stop="select('')" :class="{'active': value}").close-icon
      div(slot="popup")
        .app-select__search(v-if="searchable")
          .app-input.app-input_text
            .app-input__wrapper
              .app-input__before
                i.material-icons search
              input(:value="search" @input="updateSearch($event.target.value)" placeholder="Search")
        template(v-for="option in searchedOptions")
          div(:key="option[slugKey]" :class="['app-select__option', {selected: option[slugValue] === selectedValue}]" @click="select(option)")
            slot(name="option" :option="option")
              span {{ slugValue ? option[slugValue] : option }}
    span.error-message {{ errorMessages }}
</template>

<script>
import showingMixin from '@/mixins/showing.mixin';
import AppPopup from "@/components/global/BasePopup.vue"
import _ from 'lodash';

export default {
  name: 'BaseSelect',

  mixins: [showingMixin],

  props: {
    value: {
      required: false
    },
    text: Boolean,
    placeholder: String,
    label: String,
    options: Array,
    slugKey: String,
    slugValue: String,
    hideArrow: Boolean,
    error: Boolean,
    errorMessages: String,
    requiredField: Boolean,
    returnObject: Boolean,
    searchable: Boolean,
    placement: String,
    disabled: Boolean,
    classList: String,
    boundariesElement: {
      type: [String, Element],
      default: '.app-input'
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    popoverClasses: [Array, String],
    showCloseBtn: {
      type: Boolean,
      default: false
    },
    filter: Boolean
  },

  data: () => ({
    search: ''
  }),

  computed: {
    classObject: function() {
      return {
        'app-input_error': this.error,
        'app-input_text': this.text,
        'app-select_open': this.showing,
        'app-select_disabled': this.disabled,
        'app-select_filter': this.filter
      }
    },

    selectedOption() {
      if (!this.value) return ''
      if (!this.options) return ''
      let selected = this.options.find(item => {
        let comparedValue = this.slugKey ? item[this.slugKey] : item
        if (comparedValue === this.value) return item
      })
      if (!selected) return ''
      return selected
    },

    searchedOptions() {
      if (!this.search) return this.options
      return this.options.filter(option => {
        let comparedValue = this.slugValue ? option[this.slugValue] : option
        return comparedValue.toLowerCase().includes(this.search.toLowerCase())
      })
    },

    selectedValue() {
      if (!this.selectedOption) return ''
      return this.slugValue ? this.selectedOption[this.slugValue] : this.selectedOption
    }
  },

  methods: {
    select(val) {
      let _val = this.slugKey && !this.returnObject ? val[this.slugKey] : val
      this.$emit('input', _val)
      this.updateShowing(false)
    },

    updateSearch: _.debounce(function (val) {
      this.changeSearch(val)
    }, 500),

    changeSearch(val) {
      this.search = val
    },

    toggleSelect() {
      if (this.disabled) return
      this.toggle()
    }
  },

  watch: {
    'showing'() {
      if (this.showing && this.searchable) this.search = ''
      if (!this.showing) this.$emit('click:outside')
    }
  },

  components: {
    AppPopup,
    appInput: () => import('./BaseInput.vue'),
    selectCloseBtn: () => import('@/app/admin/components/SelectCloseBtn.vue'),
    baseLabel: () => import('@/components/global/actions/BaseControlLabel.vue'),
  }
}
</script>

<style lang="scss">
.app-input {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
    height: 40px;
    border: solid 1px $border-color;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);

    & input {
      flex: 1;
      width: 100%;
      outline: none;
      font-weight: 500;
      font-size: 12px;
      cursor: pointer;
      font-family: 'Roboto', sans-serif;

      &::placeholder {
        color: #BBC4CA;
        font-weight: normal;
        font-size: 12px;
        font-family: $font-roboto;
      }
    }

    &_transparent {
      background: transparent;
    }

    &_focus {
      outline: #4E7FED;
      border-color: #4E7FED;
    }
  }

  &_ {
    &error {
      .app-input__wrapper {
        border-color: $error-color;
      }

      .material-icons {
        color: $error-color;
      }
    }
    &text {
      margin: 0;
      box-shadow: unset;

      .app-input {
        &__wrapper {
          padding: 0;
          height: 28px;
          border: none;
          box-shadow: unset;
        }

        &__before,
        &__after {
          height: 28px;
          line-height: 28px;
        }
      }
    }
  }

  &__ {
    &input {
      padding-right: 10px;

      &::placeholder {
        font-weight: 500 !important;
      }
    }
    &before {
      padding-right: 5px;
      line-height: 40px;

      & img {
        vertical-align: middle;
      }

      &_highlight {
        .material-icons {
          color: $primary-color;
        }
      }
    }
    &after {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}

.app-select {
  position: relative;
  display: block;
  width: 100%;
  border: none;
  transition: all 200ms linear;
  user-select: none;

  &_ {
    &disabled {
      opacity: .8;
      cursor: not-allowed;

      input {
        cursor: not-allowed;
      }

      .app-input__after {
        cursor: not-allowed;
      }
    }

    &filter {
      margin-right: 15px;
      max-width: 140px;

      @include media("<=tablet") {
        margin: 0 5px 5px 0;
      }

      .app-input__wrapper {
        background-color: #f5f7f9;
        border: none;
        box-shadow: none;

        .app-input__input {
          &::placeholder {
            color: $placeholder-color;
          }
        }
      }
    }
    &open {
      .app-input__before,
      .app_input__after {
        color: $primary-color;

        .material-icons {
          color: $primary-color;
          line-height: inherit;
        }
      }
    }
  }

  &__ {
    &wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
      padding: 0 10px;
      width: 100%;
      border: solid 1px $border-color;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
      transition: all 0.15s ease-in-out;
    }
    &search {
      display: flex;
      align-items: center;
      padding-right: 5px;
      padding-left: 11px;
      height: 40px;
      border-bottom: 1px solid #DFE7EB;

      & input {
        cursor: initial;
      }

      .material-icons {
        font-size: 16px;
      }
    }
    &arrow {
      display: flex;
      margin-left: auto;
      transition: transform 0.15s ease-in-out;
      transform: rotate(0deg);

      &_open {
        transition: transform 0.15s ease-in-out;
        transform: rotate(-180deg);
      }
    }
    &option {
      display: flex;
      overflow: hidden;
      align-items: center;
      flex-direction: row;
      padding: 5px 10px;
      color: $input-color;
      white-space: nowrap;
      font-weight: 500;
      font-size: 12px;
      font-family: $font-roboto;
      user-select: none;
      cursor: pointer;

      &:hover {
        background-color: #ECF2FF;
        transition: all 0.15s ease-in-out;
      }
      
      &.selected {
        background-color: #ECF2FF;
        transition: all 0.15s ease-in-out;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
      }
    }
  }

  .app-dropdown__container {
    position: absolute;
    top: 70px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    overflow-y: scroll;
    max-width: 200px;
    width: 100%;
    height: 300px;
    border-radius: 8px;
    background-color: $body-bg;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 5%);
  }

  .dropdown-option {
    transition: all 0.15s ease-in-out;
    cursor: auto;

    &:hover {
      background-color: #ECF2FF;
      transition: all 0.15s ease-in-out;
    }

    &.selected {
      background-color: #ECF2FF;
      transition: all 0.15s ease-in-out;
    }

    &--cell {
      display: flex;
      overflow: hidden;
      align-items: center;
      flex-direction: row;
      padding: 5px 10px;
      white-space: nowrap;
      user-select: none;
    }
  }

  .icons__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 24px;
  }
}

.close-icon {
  position: absolute;
  right: -12px;
  left: auto;
  top: -8px;
  bottom: auto;
  cursor: pointer;
  display: none;

  &.active {
    display: flex;
  }
}
</style>
